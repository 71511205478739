import { useEffect, useState } from 'react'

import { DEFAULT_MASKED_PASSWORD } from '../hooks/useEditCamera'
import { useEditExternalAccount } from '../hooks/useEditExternalAccount'
import { UseFormMethods } from 'react-hook-form'

import { Box, FormLabel, HStack } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import { FormInputControl, FormInputSelectControl } from '@/components/ui'
import { DeviceCameraEditQuery } from '@/graphql/generated/operations'

import { CameraFormDataTypeI } from '../../types/types'
import {
  getRTSPPathForUi,
  getStreamingProtocolOption,
  getStreamingTypeOption,
  shouldHandleRTSPFields,
  streamingProtocols,
  streamingTypes,
  validateIPOrHostname,
  validatePort,
} from '../utils/utils'

interface EditCameraLiveStreamingFieldsIProps {
  cameraData: DeviceCameraEditQuery
  isLoading: boolean
  form: UseFormMethods<CameraFormDataTypeI>
}

export const EditCameraLiveStreamingFields = ({
  cameraData,
  isLoading,
  form,
}: EditCameraLiveStreamingFieldsIProps) => {
  // Used to keep track of the first time the username has been updated in the modal so that we can re-set the password
  const [isUsernameUpdated, setIsUsernameUpdated] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const {
    errors,
    register,
    control,
    trigger,
    getValues,
    setValue,
    watch,
    clearErrors,
  } = form
  const {
    rtspPath,
    rtspIp,
    rtspPort,
    streamingProtocol,
    streamingType,
    externalAccountId,
  } = getValues([
    'rtspPath',
    'rtspIp',
    'rtspPort',
    'streamingProtocol',
    'streamingType',
    'externalAccountId',
  ])

  const {
    externalAccountOptions,
    shouldShowExternalAccount,
    shouldShowOrganizationId,
    organizationId,
  } = useEditExternalAccount({
    streamingType,
    externalAccountId,
    setValue,
    initialExternalAccountId: cameraData?.device?.camera?.externalAccount?.id,
  })

  watch(['streamingProtocol', 'streamingType', 'externalAccountId'])
  const shouldShowRTSPFields = shouldHandleRTSPFields(streamingType?.value)

  const setLiveStreamType = () => {
    setValue('streamingType', getStreamingTypeOption(cameraData))
  }

  const setRTSPCredentials = () => {
    setValue('rtspPath', cameraData?.device?.camera?.rtspPath)
    setValue('rtspUsername', cameraData?.device?.camera?.rtspUsername)
    setValue('rtspIp', cameraData?.device?.camera?.rtspIp)
    setValue('rtspPort', cameraData?.device?.camera?.rtspPort)
    setValue('streamingProtocol', getStreamingProtocolOption(cameraData))
    if (cameraData?.device?.camera?.rtspUsername) {
      // This is a default value to populate the input, its not actually used.
      setValue('rtspPassword', DEFAULT_MASKED_PASSWORD)
    }
    setIsFirstLoad(false)
  }

  const handleUsernameUpdated = () => {
    const currentUsername: string = getValues('rtspUsername')

    const passwordIndex = 'rtspPassword'

    // Reset the first time that this is called
    if (!isUsernameUpdated) {
      setValue(passwordIndex, '')
      setIsUsernameUpdated(true)
    }

    if (currentUsername.length === 0) {
      // Changed, but empty - allow password to be empty
      register(passwordIndex, { required: false })
    } else {
      // Changed & not empty - we want to require that users include password
      register(passwordIndex, { required: true })
    }

    trigger([passwordIndex])
  }

  /**
   * Sets up input validators where we want to make sure input matches something. e.x. Port isn't a string, and is a valid port number
   */
  const setupInputValidation = () => {
    clearErrors(['rtspIp', 'rtspPort'])
    register('rtspIp', {
      validate: (rtspIp: string): string | boolean => {
        return validateIPOrHostname(rtspIp)
      },
    })
    register('rtspPort', {
      validate: (port: string): string | boolean => {
        return validatePort(port)
      },
    })
  }

  useEffect(() => {
    setLiveStreamType()
  }, [])

  useEffect(() => {
    if (shouldShowRTSPFields && isFirstLoad) {
      setRTSPCredentials()
    }
    setupInputValidation()
  }, [streamingType?.value])

  return (
    <AnimatePresence key='editCamera'>
      <Box key='streamingType' mb='3'>
        <FormInputSelectControl
          control={control}
          data-testid='devicesPage_editCameraModal_streamingType'
          defaultValue={null}
          errorMessage='Select a streaming type'
          id='streamingType'
          isClearable
          isInvalid={!!errors?.streamingType}
          label='Streaming Type'
          options={streamingTypes}
          placeholder='Select Streaming Type'
          rules={{ required: true }}
        />
      </Box>
      {shouldShowExternalAccount && (
        <>
          <Box mb='3'>
            <FormInputSelectControl
              control={control}
              dataTestId='devicesPage_editCameraModal_externalAccount'
              defaultValue={null}
              id='externalAccountId'
              isClearable
              isSearchable
              label='External Account'
              options={externalAccountOptions}
              placeholder='Select External Account'
            />
          </Box>
          {shouldShowOrganizationId && (
            <Box mb='3'>
              <FormInputControl
                control={control}
                data-testid='devicesPage_editCameraModal_orgId'
                id='orgId'
                isDisabled
                label='Organization ID'
                value={organizationId}
              />
            </Box>
          )}
        </>
      )}
      {shouldShowRTSPFields && (
        <>
          <HStack align='stretch' key='streamingProtocol' mb='4' spacing='3'>
            <FormInputSelectControl
              control={control}
              data-testid='devicesPage_editCameraModal_streamingProtocol'
              defaultValue={null}
              errorMessage={!!errors?.streamingProtocol && 'Select Protocol'}
              id='streamingProtocol'
              isClearable
              isDisabled={isLoading}
              isInvalid={!!errors?.streamingProtocol}
              label='Protocol'
              options={streamingProtocols}
              placeholder='Select Protocol'
              tooltipText='Camera protocol'
            />
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspPath'
              errorMessage={errors.rtspPath?.message}
              id='rtspPath'
              inputRef={register}
              isInvalid={Boolean(errors.rtspPath)}
              label='RTSP Path'
              onChange={() => trigger(['rtspPath'])}
              placeholder='Enter RTSP Path'
              tooltipText='Path of the RTSP feed'
            />
          </HStack>

          <HStack align='stretch' key='rtspIp' mb='4' spacing='3'>
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspIP'
              errorMessage={errors.rtspIp?.message}
              id='rtspIp'
              inputRef={register}
              isInvalid={Boolean(errors.rtspIp)}
              label='RTSP IP/hostname'
              onChange={() => trigger(['rtspIp'])}
              placeholder='Enter RTSP IP'
              tooltipText='IP address or hostname of the RTSP feed'
            />
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspPort'
              errorMessage={errors.rtspPort?.message}
              id='rtspPort'
              inputRef={register}
              isInvalid={Boolean(errors.rtspPort)}
              label='RTSP Port'
              onChange={() => trigger(['rtspPort'])}
              placeholder='Enter RTSP Port'
              tooltipText='Port of the RTSP feed'
            />
          </HStack>
          <HStack align='stretch' key='rtspUsername' mb='4' spacing='3'>
            <FormInputControl
              data-testid='devicesPage_editCameraModal_rtspUsername'
              errorMessage={errors.rtspUsername?.message}
              id='rtspUsername'
              inputRef={register}
              isInvalid={Boolean(errors.rtspUsername)}
              label='RTSP User Name'
              onChange={handleUsernameUpdated}
              placeholder='Enter RTSP User Name'
              tooltipText='User name for RTSP credentials'
            />

            <FormInputControl
              errorMessage={errors.rtspPassword?.message}
              id='rtspPassword'
              inputRef={register}
              isInvalid={Boolean(errors.rtspPassword)}
              label='RTSP Password'
              placeholder='Enter RTSP Password'
              tooltipText='Password for RTSP credentials'
              type='password'
            />
          </HStack>

          <Box mb={3}>
            <FormLabel
              color='#2D2E41'
              fontSize='16px'
              margin='0px 0px 8px'
              opacity={0.5}
            >
              RTSP URL
            </FormLabel>
            <Box
              color='#2D2E41'
              fontSize='14px'
              fontWeight='semibold'
              opacity={0.5}
            >
              {getRTSPPathForUi(
                streamingProtocol?.value,
                rtspIp,
                rtspPort,
                rtspPath
              )}
            </Box>
          </Box>
        </>
      )}
    </AnimatePresence>
  )
}
