import { DEFAULT_MASKED_PASSWORD } from '../hooks/useEditCamera'
import { usePermissions } from '@/hooks/usePermissions'
import { DeepMap, FieldValues } from 'react-hook-form'

import {
  DeviceCameraEditQuery,
  ExternalAccountsQuery,
} from '@/graphql/generated/operations'
import { StreamingProtocol, StreamingType } from '@/graphql/generated/schemas'
import { OptionI } from '@/utils/forms/optionBuilders'

import {
  CameraFormDataTypeI,
  DeviceKeys,
  LiveKeys,
  UpdateCameraInputsI,
} from '../../types/types'

// Created ENG-19167 to remove the below RegEx

// TODO - This appears to be checking both a host and an IP in the same regex.
//  Suggest splitting out to two different more specialized checks, and using
//  one or the other, or both if that's what your context calls for.
// TODO - Use validator
const ipRegex = /^[A-Za-z0-9_$.+!*()-]*$/

// TODO - Use validator
const portRegex =
  /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/

// TODO - Use validator
const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/

/**
 * Constructs a streaming URL path based on the provided parameters **to be
 * displayed on the UI!**. This should _not_ be used to actually connect as at
 * least `username` & `password` be masked with placeholders. Any additional
 * missing parameters will be replaced with placeholders as well.
 *
 * @param {string} streamingProtocol - The streaming protocol to use (should be either 'rtsp' or 'rtsps')
 * @param {string} ip - The IP address or hostname of the streaming server
 * @param {string} port - The port number for the streaming connection
 * @param {string} path - The resource path on the streaming server
 *
 * @returns {string} A formatted URL string in the format:
 *                   `protocol://{{username}}:{{password}}@ip:port/path`
 *                   where any missing parameters are replaced with placeholder values
 *
 * @example
 * // Returns "rtsp://{{username}}:{{password}}@192.168.1.100:554/stream1"
 * getRTSPPathForUi("rtsp", "stream1", "192.168.1.100", "554");
 *
 * @example
 * // Returns "rtsps://{{username}}:{{password}}@192.168.1.100:{{port}}/{{path}}"
 * getRTSPPathForUi("rtsps", "", "192.168.1.100", "");
 */
export const getRTSPPathForUi = (
  streamingProtocol: string,
  ip: string,
  port: string,
  path: string
): string => {
  const protocolToShow = streamingProtocol ? streamingProtocol : '{{protocol}}'
  const ipToShow = ip ? ip : '{{ip/host}}'
  const portToShow = port ? port : '{{port}}'
  const pathToShow = path ? path : '{{path}}'

  return `${protocolToShow}://{{username}}:{{password}}@${ipToShow}:${portToShow}/${pathToShow}`
}

export const validatePort = (value: string): boolean | string => {
  if (value.length > 0 && !portRegex.test(value)) {
    return 'Enter a valid port'
  } else {
    return true
  }
}

export const validateIPOrHostname = (value: string): boolean | string => {
  if (value.length > 0 && !ipRegex.test(value)) {
    return 'Enter a valid ip or hostname'
  } else {
    return true
  }
}

export const validateMac = (value: string): string | boolean => {
  if (value.length > 0 && !macAddressRegex.test(value)) {
    return 'Enter a valid mac address'
  } else {
    return true
  }
}

export const getStreamingProtocolOption = (data: DeviceCameraEditQuery) => {
  return streamingProtocols?.find(
    (i) => i.value === data?.device?.camera?.streamingProtocol
  )
}

export const getStreamingTypeOption = (data: DeviceCameraEditQuery) => {
  return streamingTypes?.find(
    (i) => i.value === data?.device?.camera?.streamingType
  )
}

export const getCameraInputs = (
  id: string,
  values: CameraFormDataTypeI
): UpdateCameraInputsI => {
  if (shouldHandleRTSPFields(values?.streamingType?.value)) {
    return {
      id,
      externalId: values.externalId,
      rtspPath: values.rtspPath,
      rtspUsername: values.rtspUsername,
      rtspIp: values.rtspIp,
      rtspPort: values.rtspPort ? parseInt(values.rtspPort) : null,
      streamingProtocol: values.streamingProtocol?.value,
      streamingType: values.streamingType?.value,
      ...(values.rtspPassword !== DEFAULT_MASKED_PASSWORD && {
        rtspPassword: values.rtspPassword,
      }),
      externalAccountId: values.externalAccountId?.value,
    }
  } else {
    return {
      id,
      externalId: values.externalId,
      streamingType: values.streamingType?.value,
      //Reset rtsp fields
      rtspPath: '',
      rtspUsername: '',
      rtspPassword: '',
      rtspIp: '',
      rtspPort: 554,
      streamingProtocol: StreamingProtocol.Rtsp,
      externalAccountId: values.externalAccountId?.value,
    }
  }
}

export const streamingProtocols = [
  { value: StreamingProtocol.Rtsp, label: StreamingProtocol.Rtsp },
  { value: StreamingProtocol.Rtsps, label: StreamingProtocol.Rtsps },
]

export const streamingTypes = [
  { value: StreamingType.OnPremise, label: 'On Premise' },
  { value: StreamingType.VerkadaCloud, label: 'Verkada Cloud' },
  { value: StreamingType.AvaCloud, label: 'Ava Cloud' },
  { value: StreamingType.AvigilonCloud, label: 'Avigilon Cloud' },
]

export const shouldHandleRTSPFields = (
  streamingType: StreamingType
): boolean => {
  return (
    streamingType === StreamingType.OnPremise ||
    streamingType === StreamingType.AvaCloud
  )
}

export const isLiveStreamingSetup = (
  cameraData: DeviceCameraEditQuery,
  isOrangePeel: boolean
): boolean => {
  const isRtspConfigured =
    cameraData?.device?.camera?.rtspPath &&
    cameraData?.device?.camera?.rtspUsername &&
    cameraData?.device?.camera?.streamingType === StreamingType.OnPremise
  const isVerkadaConfigured =
    cameraData?.device?.camera?.streamingType === StreamingType.VerkadaCloud
  const isAvaConfigured =
    cameraData?.device?.camera?.streamingType === StreamingType.AvaCloud
  const isAvigilonConfigured =
    cameraData?.device?.camera?.streamingType === StreamingType.AvigilonCloud

  return (
    (isRtspConfigured ||
      isVerkadaConfigured ||
      isAvaConfigured ||
      isAvigilonConfigured) &&
    isOrangePeel
  )
}

export const isDeviceFieldsDirty = (
  dirtyFields: DeepMap<FieldValues, true>
): boolean => {
  return Object.keys(dirtyFields).find(
    (key: DeviceKeys) =>
      key === 'name' || key === 'location' || key === 'macAddress'
  )
    ? true
    : false
}

export const isLiveFieldsDirty = (
  dirtyFields: DeepMap<FieldValues, true>
): boolean => {
  return Object.keys(dirtyFields).find(
    (key: LiveKeys) =>
      key === 'externalId' ||
      key === 'streamingProtocol' ||
      key === 'streamingType' ||
      key === 'rtspPath' ||
      key === 'rtspUsername' ||
      key === 'rtspPassword' ||
      key === 'rtspIp' ||
      key === 'rtspPort' ||
      key === 'externalAccountId'
  )
    ? true
    : false
}

export const shouldHandleExternalAccountField = (
  streamingType: StreamingType
) => {
  const { shouldShowExternalAccountFields } = usePermissions()
  const externalAccountAllowedStreamingTypes = [
    StreamingType.VerkadaCloud,
    StreamingType.AvigilonCloud,
  ]
  return shouldShowExternalAccountFields
    ? externalAccountAllowedStreamingTypes.includes(streamingType)
    : false
}

export const getExternalAccount = (
  externalAccountOptions: OptionI[],
  externalAccountId: string
) => externalAccountOptions.find((option) => option.value === externalAccountId)

export const getOrganizationId = (
  data: ExternalAccountsQuery,
  externalAccountId: OptionI
) =>
  data?.externalAccounts?.edges?.find(
    (account) => account?.node?.id === externalAccountId?.value
  )?.node?.accountId || ''
